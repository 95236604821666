/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import img from '../assets/img/block.png';
import BgImage from './BgImage';

function NoteBlock({ children, ...props }) {
  return (
    <BgImage
      aspect={(100 / 800) * 1188}
      img={img}
      css={css`
        transform: rotate(5deg);
      `}
      {...props}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
          color: #000;
          padding: 32% 12% 9% 10%;
          box-sizing: border-box;
          hyphens: auto;
          font-size: 120%;
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          {children}
        </div>
      </div>
    </BgImage>
  );
}

export default NoteBlock;
