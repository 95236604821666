/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import date from '../assets/img/date.png';

function Date(props) {
  return (
    <div {...props}>
      <img
        alt=""
        src={date}
        css={css`
          width: 100%;
          height: auto;
        `}
      />
    </div>
  );
}

export default Date;
