/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useCallback, useRef, useEffect } from 'react';

import Select from './Select';

const questions = {
  me: {
    depends: null,
    phrases: ['Ich, ', 'input:', ','],
  },
  coming: {
    depends: { me: [['!']] },
    phrases: [
      'komme',
      [
        'alleine',
        'zu zweit',
        'zu dritt',
        'zu viert',
        'zu fünft',
        'leider nicht',
      ],
      'nach Albikon',
    ],
  },
  pitty: {
    depends: { coming: [['leider nicht'], ['!']] },
    phrases: ['Sooooo schaaaaade'],
    actions: ['abschicken'],
  },
  when: {
    depends: { coming: [['!leider nicht'], ['!']] },
    phrases: ['Am', ['Nachmittag', 'Abend']],
  },
  stayOrGo: {
    depends: {
      when: [['!']],
    },
    phrases: ['Und bleibe', ['bis abends', 'bis nachts', 'über Nacht']],
  },
  stay: {
    depends: { stayOrGo: [['über Nacht']] },
    phrases: ['Ich schlafe', ['im Haus', 'in Scheune', 'im eigenen Zelt']],
  },
  transportation: {
    depends: { stayOrGo: [['!']] },
    phrases: [
      'Ich fahre mit dem',
      ['Auto aber ist schon voll', 'Auto und kann jemanden mitnehmen', 'ÖV'],
    ],
  },
  rideFrom: {
    depends: { transportation: [['Auto und kann jemanden mitnehmen']] },
    phrases: ['Ich werde kontaktiert deswegen!'],
  },
  activity: {
    depends: { transportation: [['!']] },
    phrases: [
      'Wenn ich schon komme,',
      [
        'mache ich einfach Party',
        'singe ich ein Lied',
        'bringe ich einen Witz',
        'organisiere ich eine Tombola',
        'mache ich DJ',
        'bringe ich eine Überraschung',
        'bringe ich ein Dessert',
        'bringe ich einen Salat',
        'bringe ich sonst etwas',
      ],
    ],
  },
  submit: {
    depends: { activity: [['!']] },
    phrases: ['top!'],
    actions: ['abschicken'],
  },
};

const checkDependency = (questionKey, answers) => {
  const question = questions[questionKey];
  if (!question) return false;
  if (!question.depends) return true;
  const dependencyKeys = Object.keys(question.depends).filter(
    (k) => Object.keys(answers).indexOf(k) !== -1
  );
  if (dependencyKeys.length !== Object.keys(question.depends).length)
    return false;

  let valid = true;
  // lets check for AND over multiple dependant keys
  dependencyKeys.forEach((dependencyKey) => {
    // lets check for AND in a single dependant key
    question.depends[dependencyKey].forEach((nested) => {
      // check for OR
      if (
        !nested.find((value) => {
          if (value.trim().indexOf('!') === 0) {
            // NOT
            return answers[dependencyKey].indexOf(value.substring(1)) === -1;
          }
          return answers[dependencyKey].indexOf(value) !== -1;
        })
      ) {
        valid = false;
      }
    });
  });
  return valid;
};

const cleanAnswers = (answers) => {
  Object.keys(questions).forEach((k) => {
    if (answers[k] && !checkDependency(k, answers)) {
      delete answers[k];
      return cleanAnswers(answers);
    }
  });
  return answers;
};

const answersToTxt = (answers) => {
  return Object.keys(answers)
    .map((k) => answers[k].join(' '))
    .join('\r\n');
};

const Answer = ({ id, answers, onPick, onSubmit }) => {
  const myAnswers = useRef([]);
  const [isShown, setIsShown] = useState(false);

  const initAnswers = useCallback(() => {
    myAnswers.current = questions[id]['phrases'].map((phr) =>
      typeof phr === 'object' ? '' : phr.indexOf('input:') === 0 ? '' : phr
    );
  }, [id, myAnswers]);

  useEffect(() => {
    initAnswers();
  }, [initAnswers]);

  useEffect(() => {
    if (isShown) return;
    initAnswers();
  }, [initAnswers, isShown]);

  useEffect(() => {
    setIsShown(!!checkDependency(id, answers));
  }, [id, answers, setIsShown]);
  return (
    isShown && (
      <div>
        {questions[id]['phrases'].map((phr, i) =>
          typeof phr === 'object' ? (
            <Select
              key={i}
              options={phr}
              onChange={(evt) => {
                myAnswers.current[i] = evt.target.value;
                onPick(id, myAnswers.current);
              }}
              defaultValue={myAnswers.current[i]}
            />
          ) : phr.indexOf('input:') === 0 ? (
            <input
              key={i}
              type="text"
              placeholder="mein Name"
              css={css`
                appearance: none;
                border-radius: 0;
                padding: 0;
                margin: 0;
                font: inherit;
                border: 0;
                border-bottom: 1px solid currentColor;
                background: transparent;
                display: inline-block;
                width: 5em;
              `}
              onChange={(evt) => {
                myAnswers.current[i] = evt.target.value;
                onPick(id, myAnswers.current);
              }}
            ></input>
          ) : (
            <span key={i}>{phr}</span>
          )
        )}
        {!!questions[id]['actions'] &&
          questions[id]['actions'].map((action, i) => (
            <button
              key={i}
              onClick={onSubmit}
              css={css`
                display: block;
                appearance: none;
                font: inherit;
                border: 2px solid black;
                border-radius: 2px;
                pointer-events: all;
                margin: 0 0.2em;
                padding: 0 0.3em;
                position: relative;
                background: black;
                color: white;
              `}
            >
              {action}
            </button>
          ))}
      </div>
    )
  );
};

const Quiz = ({ options, setBg, ...props }) => {
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = useCallback(() => {
    const xhr = new XMLHttpRequest(),
      data = new FormData();
    data.append('txt', answersToTxt(answers));

    xhr.addEventListener('load', (evt) => {
      if (xhr.status !== 200) {
        setBg('https://media1.giphy.com/media/KHJw9NRFDMom487qyo/giphy.gif');
        requestAnimationFrame(() =>
          requestAnimationFrame(() => alert('Mist, irgendwas lief schief!'))
        );
        return;
      }
      setBg('https://media4.giphy.com/media/4rW9yu8QaZJFC/giphy.gif');
      setIsSubmitted(true);
    });
    xhr.addEventListener('error', () => {
      alert('Mist, irgendwas lief schief!');
    });
    xhr.open('POST', './receive.php');
    xhr.send(data);
  }, [answers, setIsSubmitted, setBg]);

  const onPick = useCallback(
    (key, answer) => {
      switch (key) {
        case 'coming':
          if (answer.indexOf('leider nicht') !== -1) {
            setBg('https://media1.giphy.com/media/UMUZgoNFcWTgQ/giphy.gif');
          } else if (answer[3] === '') {
            setBg(
              'https://media1.giphy.com/media/xT5LMFZDsj0AKUDYTS/giphy.gif'
            );
          } else {
            if (answer.indexOf('alleine') !== -1) {
              setBg('https://media0.giphy.com/media/nXxOjZrbnbRxS/giphy.gif');
            } else if (answer.indexOf('zu zweit') !== -1) {
              setBg(
                'https://media0.giphy.com/media/3mJKS1BumESheJPvQJ/giphy.gif'
              );
            } else if (answer.indexOf('zu dritt') !== -1) {
              setBg('https://media0.giphy.com/media/dkGhBWE3SyzXW/giphy.gif');
            } else if (answer.indexOf('zu viert') !== -1) {
              setBg(
                'https://media4.giphy.com/media/TgKEjjz1lzjmEsuz80/giphy.gif'
              );
            } else {
              setBg(
                'https://media2.giphy.com/media/4VUgpQ9FiYEBCA9wM1/giphy.gif'
              );
            }
          }
          break;

        case 'when':
          setBg(
            answer.indexOf('Nachmittag') !== -1
              ? 'https://media3.giphy.com/media/XzoxWNYbWd8jomM6Qx/giphy.gif'
              : 'https://media4.giphy.com/media/4HrFB9DeAGcqM7WVdx/giphy.gif'
          );
          break;

        case 'stayOrGo':
          setBg(
            answer.indexOf('bis abends') !== -1
              ? 'https://media0.giphy.com/media/d5mI2F3MxCTJu/giphy.gif'
              : answer.indexOf('bis nachts') !== -1
              ? 'https://media3.giphy.com/media/gjgWQA5QBuBmUZahOP/giphy.gif'
              : 'https://media4.giphy.com/media/KctNhiy99LoLBTgLNO/giphy.gif'
          );
          break;

        case 'stay':
          if (answer.indexOf('im Haus') !== -1) {
            setBg('https://media3.giphy.com/media/Jalikml2XiUgw/giphy.gif');
          } else if (answer.indexOf('in Scheune') !== -1) {
            setBg(
              Math.random() > 0.5
                ? 'https://media0.giphy.com/media/3o7aCX53r92P2UYnXW/giphy.gif'
                : 'https://media0.giphy.com/media/1gdueNCQZlnfFXytAx/giphy.gif'
            );
          } else if (answer.indexOf('im eigenen Zelt') !== -1) {
            setBg(
              'https://media3.giphy.com/media/JOQqQxajAjc9M0VjoG/giphy.gif'
            );
          }
          break;

        case 'transportation':
          if (answer.indexOf('Auto aber ist schon voll') !== -1) {
            setBg(
              'https://media1.giphy.com/media/cC8Wf6NCCSZYW0s7s4/giphy.gif'
            );
          } else if (
            answer.indexOf('Auto und kann jemanden mitnehmen') !== -1
          ) {
            setBg('https://media3.giphy.com/media/fdiYL3Znprnt6/giphy.gif');
          } else if (answer.indexOf('ÖV') !== -1) {
            setBg('https://media3.giphy.com/media/NGSbD5vI6lUvC/giphy.gif');
          }
          break;
        case 'activity':
          setBg(
            answer.indexOf('bringe ich eine Überraschung') !== -1
              ? 'https://media3.giphy.com/media/RoL14FwtzxCGu0k7J0/giphy.gif'
              : answer.indexOf('bringe ich einen Witz') !== -1
              ? 'https://media4.giphy.com/media/u0LxmF9QVeDoQ/giphy.gif'
              : answer.indexOf('singe ich ein Lied') !== -1
              ? 'https://media1.giphy.com/media/vOxiC0rt7hbs4/giphy.gif'
              : answer.indexOf('bringe ich einen Salat') !== -1
              ? 'https://media1.giphy.com/media/12ncwou50HWKOc/giphy.gif'
              : answer.indexOf('bringe ich ein Dessert') !== -1
              ? 'https://media1.giphy.com/media/AiF8ZsTESrDwRjEcIU/giphy.gif'
              : answer.indexOf('organisiere ich eine Tombola') !== -1
              ? 'https://media1.giphy.com/media/3o6MboNFtQ3bUIAgVi/giphy.gif'
              : answer.indexOf('mache ich einfach Party') !== -1
              ? 'https://media1.giphy.com/media/sd0hISfBza3ZMWQ7Rn/giphy.gif'
              : answer.indexOf('bringe ich sonst etwas') !== -1
              ? 'https://media3.giphy.com/media/aLdiZJmmx4OVW/giphy.gif'
              : answer.indexOf('mache ich DJ') !== -1
              ? 'https://media2.giphy.com/media/RHENPhlgf9zzJUz23y/giphy.gif'
              : 'https://media1.giphy.com/media/tGokBeqpHuSgE/giphy.gif'
          );
          break;

        default:
          break;
      }

      setAnswers(cleanAnswers({ ...answers, [key]: answer }));
    },
    [answers, setAnswers, setBg]
  );

  return (
    <div {...props}>
      {isSubmitted ? (
        <div>Top, hat geklappt!</div>
      ) : (
        Object.keys(questions).map((key) => {
          return (
            <Answer
              key={key}
              id={key}
              answers={answers}
              onPick={onPick}
              onSubmit={onSubmit}
            />
          );
        })
      )}
    </div>
  );
};

export default Quiz;
