/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';

import groggy from '../assets/img/groggy.png';

const Tab = ({ label, path, location, setTab }) => {
  return (
    <div
      role="button"
      onClick={() => setTab(path)}
      css={css`
        text-decoration: ${location === path ? 'none' : 'underline'};
      `}
    >
      {label}
    </div>
  );
};

function Map(props) {
  const [tab, setTab] = useState('map');
  return (
    <div
      css={css`
        text-align: center;
      `}
      {...props}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-around;
          line-height: 0.6;
        `}
      >
        <Tab label="Karte" path="map" location={tab} setTab={setTab} />
        <Tab label="Adresse" path="address" location={tab} setTab={setTab} />
        <Tab label="Auto" path="auto" location={tab} setTab={setTab} />
        <Tab label="ÖV" path="oev" location={tab} setTab={setTab} />
      </div>
      {tab === 'map' && (
        <img
          alt=""
          src={groggy}
          css={css`
            margin-top: 1em;
            width: 100%;
            height: auto;
          `}
        />
      )}
      {tab === 'address' && (
        <p>
          Cristina & Felix Quartu
          <br />
          (& Fenya, Luana, Amaretta, Gusti)
          <br />
          Albikon 399
          <br />
          9533 Kirchberg
          <br />
          <br />
          <a
            target="_blank"
            rel="noreferrer"
            css={css`
              text-decoration: underline;
            `}
            href="https://www.google.com/maps/place/Albikon+399,+9533+Kirchberg/@47.4100008,9.0138403,17z/data=!3m1!4b1!4m5!3m4!1s0x479aea75722cd65f:0x51a0a2473c9a4606!8m2!3d47.4100008!4d9.016029"
          >
            Google Maps
          </a>
        </p>
      )}
      {tab === 'auto' && (
        <p>
          Mit dem Auto kann man von Zürich aus schön überland via Turbenthal /
          Bichelsee / Fischingen <br />
          oder <br />
          via Wald ZH, über die Hulftegg und dann durch Gähwil hintendurch
          <br />
          oder
          <br />
          über Sternenberg
          <br />
          oder <br />
          man nimmt die A1 nach Wil und biegt dann in Richtung Toggenburg ab.
        </p>
      )}
      {tab === 'oev' && (
        <p>
          Mit dem Zug von Zürich aus nach Wil, dort umsteigen auf den Bus
          Richtung Gähwil bis Station Tüfrüti. Von dort aus gehts zu Fuss eine
          knappe halbe Stunde bis zum Ziel.
          <br />
          <br />
          Wir können auch einen Gruppentransport ab Kirchberg organisieren,
          meldet euch doch!
          <br />
          <br />
          Letzte Verbindung nach Zürich:
          <br /> 0.31 Uhr
          <br />
        </p>
      )}
    </div>
  );
}

export default Map;
