/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

function BgImage({ aspect, img, children, ...props }) {
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: ${aspect}%;
      `}
      {...props}
    >
      <div
        css={css`
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-image: url(${img});
          background-size: 100% 100%;
        `}
      >
        {children}
      </div>
    </div>
  );
}

export default BgImage;
