/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import { Fragment, useState, useEffect } from 'react';

import Nav from './components/Nav';
import Overlay from './components/Overlay';
import Switch from './components/Switch';
import Layout from './components/Layout';
import NoteBlock from './components/NoteBlock';
import ToAlbikon from './components/ToAlbikon';
import Quiz from './components/Quiz';
import Map from './components/Map';
import Date from './components/Date';

function App() {
  const [location, setLocation] = useState('home');
  const [bg, setBg] = useState(null);
  useEffect(() => {
    setBg(
      location === 'wo'
        ? 'https://media2.giphy.com/media/d2jjuAZzDSVLZ5kI/giphy.gif'
        : location === 'wann'
        ? 'https://media0.giphy.com/media/l2YWxPbinlJYX5zMc/giphy.gif'
        : location === 'wer'
        ? 'https://media2.giphy.com/media/MTKpZ9rSgYYthtxf4e/giphy.gif'
        : null
    );
  }, [setBg, location]);
  return (
    <Fragment>
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

          html,
          body {
            font-size: 100%;
            width: 100%;
            height: 100%;
            margin: 0;
          }

          body {
            font-family: 'Patrick Hand', sans-serif;
          }

          #root {
            width: 100%;
            height: 100%;
          }

          :root {
            --color-primary-fg: #333;
            --color-primary-bg: #eee;

            @media screen and (prefers-color-scheme: dark) {
              --color-primary-fg: #eee;
              --color-primary-bg: #333;
            }
          }

          body {
            background: var(--color-primary-bg);
            color: var(--color-primary-fg);
          }

          a {
            color: inherit;
          }
        `}
      />
      <Nav location={location} setLocation={setLocation} />
      <ToAlbikon bg={bg} />
      <Overlay isActive={location !== 'home'}>
        <Layout>
          <NoteBlock>
            <Switch isActive={location === 'wo'}>
              <Map />
            </Switch>
            <Switch isActive={location === 'wann'}>
              <Date />
            </Switch>
            <Switch isActive={location === 'wer'}>
              <Quiz setBg={setBg} />
            </Switch>
          </NoteBlock>
        </Layout>
      </Overlay>
    </Fragment>
  );
}

export default App;
