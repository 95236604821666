/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

function Select({ options, ...props }) {
  return (
    <select
      css={css`
        font: inherit;
        appearance: none;
        border: 0;
        text-decoration: underline;
        padding: 0;
        text-align: center;
        background: none;
        display: inline;
        margin: 0 0.2em;
        color: inherit;
      `}
      {...props}
    >
      <option value="">???</option>
      {options.map((v) => (
        <option key={v} value={v}>
          {v}
        </option>
      ))}
    </select>
  );
}

export default Select;
