/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import bgImg from '../assets/img/bg.jpg';

import gGif from '../assets/img/g.gif';
import gWebP from '../assets/img/g.webp';

const supportWebP = () => {
  var elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    return false;
  }
};
const hasWebP = supportWebP();
function ToAlbikon({ bg, ...props }) {
  return (
    <div
      css={css`
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url(${bgImg});
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: stretch;
      `}
      {...props}
    >
      <div
        css={css`
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background: url(${bg ? bg : hasWebP ? gWebP : gGif});
          background-size: cover;
          background-position: center;
          pointer-events: none;
          mix-blend-mode: hard-light;
        `}
      />
    </div>
  );
}

export default ToAlbikon;
