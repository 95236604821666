/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import imgWann from '../assets/img/nav-wann2.png';
import imgWo from '../assets/img/nav-wo2.png';
import imgWer from '../assets/img/nav-wer2.png';

const Item = ({ path, img, label, location, setLocation, ...props }) => {
  return (
    <button
      onClick={() => setLocation(path === location ? 'home' : path)}
      css={css`
        appearance: none;
        font: inherit;
        border: 2px solid black;
        border-radius: 2px;
        pointer-events: all;
        margin: 0 0.2em;
        padding: 0 0.3em;
        position: relative;
        background: ${location === path ? 'black' : 'white'};
        color: ${location === path ? 'white' : 'black'};
        font-size: 140%;
        box-shadow: -1px -1px white;
      `}
      {...props}
    >
      {label}
    </button>
  );
};

const Nav = ({ location, setLocation, ...props }) => {
  return (
    <div
      css={css`
        position: fixed;
        z-index: 100;
        top: 1em;
        left: 1em;
        right: 1em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        pointer-events: none;
      `}
      {...props}
    >
      <div
        css={css`
          flex: 0 0 100%;
          display: block;
          margin: 0.1em;
          padding: 0;
          color: black;
          font-size: 140%;
          text-align: center;
          text-shadow: -1px -1px white;
        `}
      >
        Hausfest @ Quartu's
      </div>
      <Item
        path="wo"
        img={imgWo}
        location={location}
        setLocation={setLocation}
        label="Wo"
      />
      <Item
        path="wann"
        img={imgWann}
        location={location}
        setLocation={setLocation}
        label="Wann"
      />
      <Item
        path="wer"
        img={imgWer}
        location={location}
        setLocation={setLocation}
        label="Wer"
      />
    </div>
  );
};

export default Nav;
